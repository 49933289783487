import React, { PropsWithChildren } from 'react';
import { parseStyles } from '../utils';

type SectionTitleProps = PropsWithChildren & {
  className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ children, className }) => (
  <h2
    className={parseStyles`
      text-4xl text-left 
      tracking-wider
      ${className}
    `}
  >
    {children}
  </h2>
);

export default SectionTitle;
