import React from 'react';
import AdvantageCard from '../AdvantageCard';

const TimeMaterialSteps = () => (
  <div className="grid grid-rows-4 md:grid-rows-2 md:grid-cols-2 xl:grid-cols-4 xl:grid-rows-1 gap-x-[3rem]">
    <AdvantageCard
      content="Собеседование специалистов"
      number={1}
    />
    <AdvantageCard
      content="Быстрый старт работ"
      number={2}
    />
    <AdvantageCard
      content="Работа специалиста под вашим управлением"
      number={3}
    />
    <AdvantageCard
      content="В любой момент можно отказаться от специалиста"
      number={4}
    />
  </div>
);

export default TimeMaterialSteps;
