import React from 'react';

interface AdvantageBlockProps {
  icon: JSX.Element;
  title: string;
  description?: string;
  pdf?: string;
}

/** Блок преимущества компании */
const AdvantageBlock: React.FC<AdvantageBlockProps> = ({
  icon, title, description, pdf,
}) => (
  <li className="text-left md:w-[33%] p-0 pb-[2rem] md:pr-[2rem] tracking-normal">
    <div className="text-[1.8rem]">{icon}</div>
    <h3 className="uppercase mb-3 w-[90%] text-xl font-semibold">
      {title}
    </h3>
    <div className="whitespace-pre-line">{description}</div>
    {pdf && (
      <>
        <br />
        <a
          className="underline decoration-dotted hover:decoration-solid"
          href={pdf}
        >
          Примеры наших работ (pdf, 20 MB)
        </a>
      </>
    )}
  </li>
);

export default AdvantageBlock;
