import React from 'react';
import { SectionClasses, paddings } from '../../enums';
import { parseStyles } from '../../utils';
import PartnersMarquee from './PartnersMarquee';

const Header: React.FC = () => (
  <div
    className={parseStyles`
        ${SectionClasses.header}
        min-h-[calc(100vh-4rem)]
        flex flex-col  
        leading-tight
        gap-[1rem]
        overflow-hidden
      `}
    id="Header"
  >
    <div className="my-auto">
      <h1
        className={parseStyles`
            text-h5 sm:text-h3 xl:text-h1 
            text-left 
            font-bold
            ${paddings.mxMain}
          `}
      >
        T&M, FixedPrice,
        <br />
        аутсорс разработка
        <br />
        для it-компаний и бизнеса
      </h1>
      <PartnersMarquee />
    </div>
    <div className={parseStyles`
        mb-[3rem] text-left 
        font-semibold 
        transition-all duration-400
        tracking-normal
        text-gray-500
        ${paddings.mxMain}`}
    >
      <div>
        <span>Работаем с 2018 года.</span>
      </div>
      <span>Входим в реестр аккредитованных ИТ-компаний с 2019 года (№10846).</span>
    </div>
  </div>
);

export default Header;
