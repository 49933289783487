import React from 'react';
import { parseStyles } from '../../utils';
import HowItWorkSection from './sections/HowItWorks';
import AdvantagesSection from './sections/AdvantagesSection';
import FixedPriceSteps from './Steps';

const FixedPricePageContent: React.FC = () => (
  <div className="flex flex-col gap-y-[2rem]">
    <h1 className={parseStyles`
        uppercase text-[2rem] font-bold
        w-fit
      `}
    >
      FixedPrice
    </h1>
    <FixedPriceSteps />
    <HowItWorkSection />
    <AdvantagesSection />
  </div>
);

export default FixedPricePageContent;
