/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  faBrain,
  faBrush,
  faMobile,
  faDatabase,
  faUsers,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DividerWithIcon from '../DividerWithIcon';
import SettingsIcon from '../../assets/icons/settings.svg';
import SectionTitle from '../SectionTitle';
import { parseStyles } from '../../utils';
import AdvantageBlock from './AdvantageBlock';
import StarIcon from '../../assets/icons/star.svg';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import pdf from '../../assets/a2soft_mobile_portfolio.pdf';

const BusinessPartnersSection: React.FC = () => (
  <>
    <DividerWithIcon
      icon={(
        <img
          alt=""
          src={SettingsIcon}
        />
      )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
      `}
    >
      <SectionTitle className="min-w-[15vw] md:min-w-[33%] md:w-[33%] md:pr-[2rem]">
        Наши направления разработки
      </SectionTitle>
      <div className="flex flex-col xl:flex-row gap-x-[1rem]">
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0">
          <ul className="list-disc list-inside">
            <li>iOS и Android приложения.</li>
            <li>Web сервисы (.NET Core).</li>
            <li>Интеграция систем.</li>
            <li>Web приложения (React, Vue, Angular).</li>
            <li>Внедрение и сопровождение CRM-систем на базе платформ MS Dynamics 365, BPMSoft (ex.Terrasoft Creatio).</li>
          </ul>
        </div>
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0">
          <span>
            В штате компании более 20 программистов и аналитиков разных грейдов,
            <br />
            в том числе архитекторы и тимлиды с опытом в области разработки более 10 лет.
          </span>
        </div>
      </div>
    </div>
    {/** Сетка технологий */}
    <DividerWithIcon
      className=""
      icon={(
        <img
          alt=""
          src={StarIcon}
        />
      )}
    />
    <ul className="flex flex-col md:flex-row flex-wrap justify-between ">
      <AdvantageBlock
        description={`SPA, TypeScript, React, Vue, Angular, ExtJS, MobX, VueX, Redux, SCSS/LESS StyledComponents, Tailwind.
        `}
        icon={(<FontAwesomeIcon icon={faBrush} />)}
        title="Frontend"
      />
      <AdvantageBlock
        description={`ASP.NET Core, Web API, .NET Framework, EF Core, Dapper, SignalR, NHibernate, Unit/Integration Tests,
          Hangfire/Quartz.`}
        icon={(<FontAwesomeIcon icon={faDatabase} />)}
        title="Backend"
      />
      <AdvantageBlock
        description="Кроссплатформенные приложения с единой кодовой базой на Flutter, React Native. Нативные приложения на Swift, Kotlin.
          Firebase Cloud Messaging, Cloud Firestore, Isar, Sqlite."
        icon={(<FontAwesomeIcon icon={faMobile} />)}
        pdf={pdf}
        title="Mobile"
      />
      <AdvantageBlock
        description="Полный цикл настройки, разработки, а также услуги по доработке CRM систем на платформах
          MS Dynamics 365 (v.8, v.9), BPMSoft (ex. Terrasoft Creatio) (v.7)."
        icon={(<FontAwesomeIcon icon={faUsers} />)}
        title="CRM системы"
      />
      <AdvantageBlock
        description={`MQ, REST, SOAP, RPC, GraphQL, Kafka, RabbitMQ, IBM MQ, Ocelot API Gateways/BFF etc.
          Опыт интеграции с различными системами: от DaData и ЕСИА до закрытых Enterprise систем.`}
        icon={(<FontAwesomeIcon icon={faLink} />)}
        title="Интеграции"
      />
      <AdvantageBlock
        description={`SQL/NOSQL: MySQL, MSSQL, Informix, PostgreSQL, ORACLE, Redis etc.
        VCS: Git, TFS, CI/CD Pieplines
        Docker, k8s, helm, EFK/LPG и другие инструменты масштабирования.`}
        icon={(<FontAwesomeIcon icon={faBrain} />)}
        title="и многое другое"
      />
    </ul>
  </>
);

export default BusinessPartnersSection;
