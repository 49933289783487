import React from 'react';
import DividerWithIcon from '../DividerWithIcon';
import SettingsIcon from '../../assets/icons/settings.svg';
import { parseStyles } from '../../utils';
import SectionTitle from '../SectionTitle';

const HowItWorkSection = () => (
  <>
    <DividerWithIcon icon={(
      <img
        alt=""
        src={SettingsIcon}
      />
    )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
        gap-x-[2rem] xl:gap-x-[5rem]
      `}
    >
      <SectionTitle className="min-w-[20vw]">
        Наша работа
      </SectionTitle>
      <div className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-[10rem]">
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0">
          <h3 className="text-xl font-semibold">Процесс</h3>
          <p className="text-left">
            Вы отдаете нашей команде написание исходного кода вашего проекта или отдельной его части.
            Мы не просто предоставляем для этого специалистов, аналогично T&M,
            но и несем ответственность за результат разработки и предоставляем гарантийные обязательства.
            <br />
            Управление проектом может быть как с вашей стороны, так и с нашей, или совместным.
            Постановку каждой реализуемой задачи может выполнять либо сам заказчик.
            Либо нам может быть предоставлено полное или частное Техническое задание,
            которое мы самостоятельно декомпозируем на релизы и задачи, входящие в них.
            На старте каждого релиза мы отдельно согласуем состав задач, которые войдут в релиз и их оценку.
          </p>
          <p className="text-left">Оплата производится ежемесячно на основании отчёта о реализованных задачах.</p>
        </div>
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0 ">
          <h3 className="text-xl font-semibold">Результат</h3>
          <ul className={parseStyles`list-disc list-inside`}>
            <li>
              Исходные коды, работающие согласно постановке задач или Техническому заданию.
            </li>
            <li>
              Исправление багов, найденных в течение гарантийного срока.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
);

export default HowItWorkSection;
