import React from 'react';
import AdvantagesSection from './sections/AdvantagesSection';
import HowItWorksSection from './sections/HowItWorksSection';
import Steps from './TimeMaterialSteps';
import { parseStyles } from '../../utils';

const TimeMaterialPageContent: React.FC = () => (
  <div className="flex flex-col gap-y-[2rem]">
    <h1 className={parseStyles`
        uppercase text-[2rem] font-bold
        w-fit
      `}
    >
      Time&Material
    </h1>
    <Steps />
    <HowItWorksSection />
    <AdvantagesSection />
  </div>

);

export default TimeMaterialPageContent;
