import React from 'react';
import DividerWithIcon from '../../DividerWithIcon';
import SectionTitle from '../../SectionTitle';
import StarIcon from '../../../assets/icons/star.svg';
import { parseStyles } from '../../../utils';

const AdvantagesSection: React.FC = () => (
  <>
    <DividerWithIcon icon={(
      <img
        alt=""
        src={StarIcon}
      />
    )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
        gap-x-[2rem] xl:gap-x-[5rem]
      `}
    >
      <SectionTitle className="min-w-[20vw]">Преимущества</SectionTitle>
      <ul className="list-disc list-inside text-left mt-r2 xl:mt-0 tracking-normal">
        <li>Быстрый старт работ и возможность точечно закрыть выпадающие ресурсы на вашем проекте.</li>
        <li>Не нужно обучать сотрудников и следить за их мотивацией.</li>
        <li>Не нужно организовывать рабочее место.</li>
        <li>В любой момент можно отказаться от специалиста.</li>
      </ul>
    </div>
  </>
);

export default AdvantagesSection;
