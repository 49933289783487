import React from 'react';
import { parseStyles } from '../utils';

interface AdvantageCardProps {
  className?: string;
  content: React.ReactNode;
  number?: number | string;
}

const AdvantageCard: React.FC<AdvantageCardProps> = ({
  className,
  content,
  number,
}) => (
  <div className={parseStyles`
      advantageCard
      bg-[#f5f5f6] text-[#181818] 
      flex items-center overflow-hidden 
      mt-[0.75rem] xl:mt-0
      transition duration-300
      hover:scale-[1.01]
      hover:shadow-md
      ${className}
    `}
  >
    <div className="grow flex h-full ml-[1.5rem] mt-[0.5rem]">
      <div className="text-sm text-left tracking-normal">{content}</div>
    </div>
    <div className="flex h-full">
      <span className={parseStyles`
        text-outlined 
        h-[7rem]
        transform translate-x-[0.7rem] translate-y-[-3.2rem]
        text-[6.5rem] font-mono font-bold
      `}
      >
        {number}
      </span>
    </div>
  </div>
);

export default AdvantageCard;
