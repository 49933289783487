/** Enum для статуса */
enum Status {
  /** Начальный статус */
  Initial,
  /** Статус загрузки */
  Fetching,
  /** Статус ошибки */
  Error,
  /** Статус нормального состояния */
  Success,
}

export default Status;

export enum links {
  main = '/',
  portfolio = '/m_portfolio',
  hardSkills = '#HardSkills',
  timeMaterial = '#TimeMaterial',
  fixedPrice = '#FixedPrice',
  outsource = '#Outsource',
  contacts = '#Contacts',
}

export enum paddings {
  mxMain = 'mx-[1rem] lg:mx-[2rem]',
  mx = 'mx-[0.5rem] sm:mx-[3rem] xl:mx-[6rem]',
  ml = 'ml-[0.5rem] sm:ml-[3rem] xl:ml-[6rem]',
  mr = 'mr-[0.5rem] sm:mr-[3rem] xl:mr-[6rem]',
  pl = 'pl-[0.5rem] sm:pl-[3rem] xl:pl-[6rem]'
}

export enum SectionClasses {
  header = 'header',
  topSection = 'top-section',
  hardSkills = 'hard-skills',
  specializations = 'specializations-section',
  advantages = 'advantages-section',
  contract = 'contract-section',
  businessPartners = 'business-partners-section',
  order = 'order-section',
  map = 'map-section',
}
