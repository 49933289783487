import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import MainPage from './views/Main';
import { links } from './enums';
import MainLayout from './components/MainLayout';
import AutoDownload from './components/AutoDownload';

const App: React.FC = () => (
  <div className="App">
    <Router>
      <Routes>
        <Route element={<MainLayout />}>
          <Route
            element={<MainPage />}
            path={links.main}
          />
        </Route>
        <Route
          element={<AutoDownload />}
          path={links.portfolio}
        />
      </Routes>
    </Router>
  </div>
);

export default App;
