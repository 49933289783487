import React from 'react';
import DividerWithIcon from '../../DividerWithIcon';
import StarIcon from '../../../assets/icons/star.svg';
import SectionTitle from '../../SectionTitle';
import { parseStyles } from '../../../utils';

const AdvantagesSection = () => (
  <>
    <DividerWithIcon icon={(
      <img
        alt=""
        src={StarIcon}
      />
    )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
        gap-x-[2rem] xl:gap-x-[5rem]
      `}
    >
      <SectionTitle className="min-w-[20vw]">
        Преимущества
      </SectionTitle>
      <ul className={parseStyles`
          text-left 
          list-disc list-inside 
          mt-r2 xl:mt-0 
          tracking-normal
        `}
      >
        <li>Не нужно управлять процессом разработки.</li>
        <li>Подходит для ограниченного бюджета на разработку.</li>
      </ul>
    </div>
  </>
);

export default AdvantagesSection;
