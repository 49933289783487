import React from 'react';
import { parseStyles } from '../utils';

interface DividerWithIconProps {
  className?: string;
  icon: React.ReactNode;
}

const DividerWithIcon: React.FC<DividerWithIconProps> = ({ icon, className }) => (
  <div className={parseStyles`
      flex gap-x-[3rem] items-center
      ${className}
    `}
  >
    <div className="w-[1.5rem] h-[1.5rem]">{icon}</div>
    <div className="h-[1px] w-full bg-black" />
  </div>
);

export default DividerWithIcon;
