/**
 * Шаблон форматирования стилей tailwind
 *
 * @example пример использования:
 *    `
 *    w-full p-[10px] pl-[15px]
 *    cursor-pointer transition-none text-left rounded-md
 *    ${isHovered || isSelected ? 'bg-option-arrow-hover' : 'bg-option'}
 *    `
 *  при добавлении parseStyles`...`
 *  будет приведено к `w-full p-[10px] pl-[15px] cursor-pointer transition-none text-left rounded-md bg-option-arrow-hover`
 */
export function parseStyles(strings: TemplateStringsArray, ...exprResults: (string | boolean | undefined)[]) {
  const rawStyles = strings
    .flatMap((x) => x.split('\n'))
    .flatMap((x) => x.split(' '))
    .map((x) => x.trim())
    .filter(Boolean);
  const rawExprResults = exprResults.filter(Boolean);
  return [...rawStyles, ...rawExprResults].join(' ');
}

export const debugSize = `
  after:content-['default'] 
  sm:after:content-['sm'] 
  md:after:content-['md'] 
  lg:after:content-['lg'] 
  xl:after:content-['xl']
  `;
