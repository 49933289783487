import React from 'react';
import DividerWithIcon from '../DividerWithIcon';
import StarIcon from '../../assets/icons/star.svg';
import SectionTitle from '../SectionTitle';
import { parseStyles } from '../../utils';

const AdvantagesSection = () => (
  <>
    <DividerWithIcon icon={(
      <img
        alt=""
        src={StarIcon}
      />
    )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
        gap-x-[2rem] xl:gap-x-[5rem]
      `}
    >
      <SectionTitle className="min-w-[20vw]">
        Преимущества
      </SectionTitle>
      <ul className={parseStyles`
        text-left 
        list-disc list-inside 
        mt-r2 xl:mt-0 tracking-normal
      `}
      >
        <li>Все преимущества T&M.</li>
        <li>Предоставляем гарантийные обязательства на результат разработки.</li>
        <li>
          Прозрачно для заказчика обеспечиваем стабильную команду,
          подменяя сотрудников в случае отпуска или болезни и обеспечивая тот же уровень качества и сроков.
        </li>
        <li>
          Соблюдаем дедлайн заказчика. За свой счёт можем выполнять работу в оверы, если не укладываемся в сроки по своей вине.
        </li>
      </ul>
    </div>
  </>
);

export default AdvantagesSection;
