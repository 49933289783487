import React from 'react';
import { parseStyles } from '../utils';
import AdvantagesSection from '../components/Outsource/AdvantagesSection';
import HowItWorkSection from '../components/Outsource/HowItWorkSection';
import { paddings } from '../enums';
import OutsourceSteps from '../components/Outsource/OutsourceSteps';

const OutsourcePage = () => (
  <div
    className={parseStyles`
      pt-[2rem] 
      md:min-h-[100vh] pb-[3rem]
      ${paddings.mxMain}
    `}
    id="Outsource"
  >
    <div className="flex flex-col gap-y-[2rem] ">
      <h1 className={parseStyles`
        uppercase text-[2rem] font-bold
        w-fit
      `}
      >
        Аутсорс
      </h1>
      <OutsourceSteps />
      <HowItWorkSection />
      <AdvantagesSection />
    </div>
  </div>
);

export default OutsourcePage;
