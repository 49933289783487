import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import { NavItemProps, SidebarItemProps } from '../types';
import { parseStyles } from '../utils';

/** Элемент навигации бокового меню */
export const SidebarItem: React.FC<NavItemProps> = ({ children, to, onClose }) => {
  const [opened, setOpened] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === to) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [location.pathname, to]);
  return (
    <Link
      className={parseStyles`
      border-b-border border-b no-underline text-textSecondary text-left font-normal text-md px-6 py-4
      hover:bg-hoverPrimary
      active:bg-activePrimary
      ${opened ? 'bg-gray-200' : ''}
    `}
      onClick={() => {
        const targetBlock = document.getElementById(to.slice(1));
        targetBlock?.scrollIntoView({ behavior: 'smooth' });
        if (onClose) {
          onClose();
        }
      }}
      to={{ pathname: '' }}
    >
      {children}
    </Link>
  );
};

interface SidebarProps {
  /** Закрытие бокового меню */
  closeSidebar: () => void;
  /** Массив элементов бокового меню */
  items: SidebarItemProps[];
  /** Состояние бокового меню (открыто/закрыто) */
  isOpen: boolean;
}

/** Боковое меню навигации */
const Sidebar: React.FC<SidebarProps> = (
  {
    closeSidebar,
    items,
    isOpen,
  },
) => {
  const sidebarBaseStyles = 'z-20 fixed h-[100vh] w-[19rem] bg-white flex flex-col transition-all duration-200';

  return (
    <div
      className={isOpen ? 'z-10 fixed h-[100vh] w-full bg-shadow transition-all duration-400' : 'invisible'}
      onClick={closeSidebar}
    >
      <div
        className={parseStyles`${sidebarBaseStyles} ${isOpen ? 'left-0 right-0' : 'left-[-100%] right-initial'} `}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="h-[3rem] bg-border uppercase text-xs">
          <div
            className={parseStyles`
            w-fit h-full 
            flex flex-row gap-x-[0.5rem] items-center
            ml-[1.5rem] font-extrabold 
            text-textPrimary cursor-pointer
            `}
            onClick={closeSidebar}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
            Закрыть меню
          </div>
        </div>
        {
          items.map((item) => (
            <SidebarItem
              key={item.to}
              onClose={() => closeSidebar()}
              to={item.to}
            >
              {item.label}
            </SidebarItem>
          ))
        }
      </div>
    </div>
  );
};
export default Sidebar;
