import React from 'react';
import DividerWithIcon from '../../DividerWithIcon';
import SettingsIcon from '../../../assets/icons/settings.svg';
import { parseStyles } from '../../../utils';
import SectionTitle from '../../SectionTitle';

const HowItWorkSection: React.FC = () => (
  <>
    <DividerWithIcon icon={(
      <img
        alt=""
        src={SettingsIcon}
      />
    )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
        gap-x-[2rem] xl:gap-x-[5rem]
      `}
    >
      <SectionTitle className="min-w-[20vw]">
        Наша работа
      </SectionTitle>
      <div className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-[10rem]">
        <div
          className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0"
          data-nosnippet
        >
          <h3 className="text-xl font-semibold">Процесс</h3>
          <p className="text-left">
            Мы за свой счёт собираем всю информацию и требования,
            необходимые для оценки работ. После согласования оценки и
            сроков работ заключаем договор, который учитывает все
            наши риски и гарантийное исправление багов в течение
            ограниченного времени (как правило от 3 месяцев до 1
            года).
            <br />
            Мы обязуемся выполнить разработку по исходным
            требованиям в обозначенную сумму и срок. В случае,
            если мы промахнулись с оценкой и не было изменения
            в исходных требованиях, то мы за свой счет доделаем
            проект и при необходимости увеличим команду,
            чтобы выдержать сроки.
          </p>
          <p>Оплата производится в два этапа: аванс 30-40% до начала работ и полный расчет после приемки вами работ.</p>
        </div>
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0 ">
          <h3 className="text-xl font-semibold">Результат</h3>
          <ul className={parseStyles`list-disc list-inside`}>
            <li>Исходные коды, работающие согласно требованиям договора.</li>
            <li>Внедрение в эксплуатацию, если это отдельно прописано в договоре.</li>
            <li>Исправление багов, найденных в течение гарантийного срока.</li>
          </ul>
        </div>
      </div>
    </div>
  </>
);

export default HowItWorkSection;
