import React from 'react';
import AdvantageCard from '../AdvantageCard';

const OutsourceSteps = () => (
  <div className="grid grid-rows-4 md:grid-rows-2 md:grid-cols-2 xl:grid-cols-4 xl:grid-rows-1 gap-x-[3rem]">
    <AdvantageCard
      content="Быстрый старт работ"
      number={1}
    />
    <AdvantageCard
      content="Согласуем оценки и состав работ на каждый спринт"
      number={2}
    />
    <AdvantageCard
      content="Обеспечиваем непрерывность процесса разработки и соблюдение сроков"
      number={3}
    />
    <AdvantageCard
      content="Гарантийная поддержка"
      number={4}
    />
  </div>
);

export default OutsourceSteps;
