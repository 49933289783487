import React from 'react';
import AdvantageCard from '../AdvantageCard';

const FixedPriceSteps = () => (
  <div className="grid grid-rows-4 md:grid-rows-2 md:grid-cols-2 xl:grid-cols-4 xl:grid-rows-1 gap-x-[3rem]">
    <AdvantageCard
      content="Проводим оценку работ"
      number={1}
    />
    <AdvantageCard
      content="Заключаем договор"
      number={2}
    />
    <AdvantageCard
      content="Демонстрируем промежуточные версии и ход работ"
      number={3}
    />
    <AdvantageCard
      content="Гарантийная поддержка"
      number={4}
    />
  </div>
);

export default FixedPriceSteps;
