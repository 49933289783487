import React from 'react';
import TimeMaterialPageContent from '../components/TimeMaterial/TimeMaterialPageContent';
import { parseStyles } from '../utils';
import { paddings } from '../enums';

const TimeMaterialPage: React.FC = () => (
  <div
    className={parseStyles`
      pt-[2rem] 
      md:min-h-[100vh] pb-[3rem]
      ${paddings.mxMain}
    `}
    id="TimeMaterial"
  >
    <head />
    <body>
      <TimeMaterialPageContent />
    </body>
  </div>
);
export default TimeMaterialPage;
