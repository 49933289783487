import React from 'react';
import FixedPricePageContent from '../components/FixedPrice/FixedPricePageContent';
import { parseStyles } from '../utils';
import { paddings } from '../enums';

const FixedPricePage: React.FC = () => (
  <div
    className={parseStyles`
      pt-[2rem] 
      md:min-h-[100vh] pb-[3rem]
      ${paddings.mxMain}
    `}
    id="FixedPrice"
  >
    <FixedPricePageContent />
  </div>
);
export default FixedPricePage;
