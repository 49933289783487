import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { links } from '../enums';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import pdf from '../assets/a2soft_mobile_portfolio.pdf';

const AutoDownload = () => {
  const navigator = useNavigate();
  useEffect(() => {
    const link = document.createElement('a');
    link.href = pdf;
    link.setAttribute(
      'download',
      'a2soft_mobile_portfolio.pdf',
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
    navigator({ pathname: links.main });
  }, []);
  return null;
};

export default AutoDownload;
