/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { ReactComponent as BarsGroup } from '../../assets/partners/barsgroup.svg';
import { ReactComponent as Ilar } from '../../assets/partners/ilar.svg';
import Innostage from '../../assets/partners/Innostage.png';
import Igs from '../../assets/partners/igs.png';
import BalaCity from '../../assets/partners/balaCity.png';
import { parseStyles } from '../../utils';

const IconStyles = 'h-[1.5rem] sm:h-[60px] cursor-pointer';

const IconsCount = 5;

const PartnersMarquee: React.FC = () => {
  const bgRef = useRef<SVGSVGElement>(null);
  const igsRef = useRef<HTMLImageElement>(null);
  const innoRef = useRef<HTMLImageElement>(null);
  const ilarRef = useRef<SVGSVGElement>(null);
  const bcaRef = useRef<HTMLImageElement>(null);

  const [spaceWidth, setSpaceWidth] = useState(10);

  const onResize = () => {
    const bgW = bgRef.current?.width.baseVal.value || 0;
    const igsW = igsRef.current?.width || 0;
    const innoW = innoRef.current?.width || 0;
    const ilarW = ilarRef.current?.width.baseVal.value || 0;
    const bcaW = bcaRef.current?.width || 0;
    const iconsW = bgW + igsW + innoW + ilarW + bcaW;
    const clientW = window.visualViewport?.width || 0;
    const tempSpaceWidth = (clientW - iconsW) / IconsCount;
    setSpaceWidth(tempSpaceWidth > 10 ? tempSpaceWidth : 10);
  };

  useEffect(() => {
    window.onresize = onResize;
    onResize();
    return () => {
      window.onresize = () => null;
    };
  }, []);

  return (
    <Marquee
      className="mt-[2rem]"
      gradient={false}
      speed={40}
    >
      <BarsGroup
        ref={bgRef}
        className={parseStyles`
            ${IconStyles}
          `}
        onClick={() => {
          window.open('https://bars.group/', '_blank');
        }}
        style={{ marginRight: spaceWidth }}
        title="АО «БАРС Груп»"
      />
      <img
        ref={igsRef}
        alt="ООО 'Айджиэс'"
        className={parseStyles`
            ${IconStyles}
          `}
        onClick={() => {
          window.open('https://i-gs.ru/', '_blank');
        }}
        src={Igs}
        style={{ marginRight: spaceWidth }}
        title="ООО 'Айджиэс'"
      />
      <img
        ref={innoRef}
        alt="ООО 'Инностейдж'"
        className={parseStyles`
            ${IconStyles}
          `}
        onClick={() => {
          window.open('https://innostage-group.ru/', '_blank');
        }}
        onLoad={onResize}
        src={Innostage}
        style={{ marginRight: spaceWidth }}
        title="ООО 'Инностейдж'"
      />
      <Ilar
        ref={ilarRef}
        className={parseStyles`
            p-2 ${IconStyles}
          `}
        onClick={() => {
          window.open('https://ilartech.com/', '_blank');
        }}
        style={{ marginRight: spaceWidth }}
        title="ООО 'Илар Групп'"
      />
      <img
        ref={bcaRef}
        alt="АНО 'БАЛА-СИТИ'"
        className={parseStyles`
            ${IconStyles}
          `}
        onClick={() => {
          window.open('https://www.balacity.ru/', '_blank');
        }}
        onLoad={onResize}
        src={BalaCity}
        style={{ marginRight: spaceWidth }}
        title="АНО 'БАЛА-СИТИ'"
      />
    </Marquee>
  );
};

export default PartnersMarquee;
