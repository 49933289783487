import React from 'react';
import DividerWithIcon from '../../DividerWithIcon';
import SettingsIcon from '../../../assets/icons/settings.svg';
import SectionTitle from '../../SectionTitle';
import { parseStyles } from '../../../utils';

const HowItWorksSection: React.FC = () => (
  <>
    <DividerWithIcon icon={(
      <img
        alt=""
        src={SettingsIcon}
      />
    )}
    />
    <div className={parseStyles`
        flex 
        flex-col xl:flex-row
        gap-x-[2rem] xl:gap-x-[5rem]
      `}
    >
      <SectionTitle className="min-w-[20vw]">
        Наша работа
      </SectionTitle>
      <div className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-[10rem]">
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0">
          <h3 className="text-xl font-semibold">Процесс</h3>
          <p className="text-left">
            Мы предоставляем в ваше управление разработчиков,
            системных и бизнес аналитиков на требуемый период.
            Вы сможете прособеседовать каждого специалиста.
          </p>
          <p className="text-left">
            Оплата производится ежемесячно на основании отчёта о затраченном рабочем времени специалистов.
          </p>
        </div>
        <div className="flex flex-col gap-y-[2rem] tracking-normal text-left mt-r2 xl:mt-0 ">
          <h3 className="text-xl font-semibold">Результат</h3>
          <ul className={parseStyles`list-disc list-inside`}>
            <li>
              Привлечённый по T&M специалист соблюдает все ваши регламенты и кодстайлы.
            </li>
            <li>
              Cпециалист выполняет любые проектные задачи под управлением вашего Руководителя проекта или тимлида,
              соблюдая оценки и сроки по задачам.
            </li>
            <li>
              Рабочее время на исправление замечаний и багов,
              выявленных по задачам привлеченного по T&M сотрудника,
              оплачивается наравне с выполнением основной задачи.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>

);

export default HowItWorksSection;
