import React from 'react';
import BusinessPartnersSection from '../components/MainPage/BusinessPartnersSection';
import { parseStyles } from '../utils';
import { paddings } from '../enums';

const HardSkillsPage = () => (
  <div
    className={parseStyles`
      pt-[2rem] 
      md:min-h-[100vh] pb-[3rem]
      gap-y-[2rem]
      flex flex-col
      ${paddings.mxMain}
    `}
    id="HardSkills"
  >
    <h1 className={parseStyles`
        uppercase text-[2rem] font-bold
        w-fit
      `}
    >
      Hardskills
    </h1>
    <BusinessPartnersSection />
  </div>
);

export default HardSkillsPage;
