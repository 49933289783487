import React from 'react';
import Header from '../components/MainPage/Header';
import OutsourcePage from './OutsourcePage';
import TimeMaterialPage from './TimeMaterialPage';
import HardSkillsPage from './HardSkillsPage';
import FixedPricePage from './FixedPricePage';

const MainPage: React.FC = () => (
  <>
    <Header />
    <HardSkillsPage />
    <TimeMaterialPage />
    <FixedPricePage />
    <OutsourcePage />
  </>
);

export default MainPage;
