/* eslint-disable max-len */
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars, faEnvelope, faLocationDot, faPhoneFlip,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { links, paddings } from '../enums';
import { NavItemProps, SidebarItemProps } from '../types';
import Sidebar from './Sidebar';
import { parseStyles } from '../utils';

/** Элемент навигации верхнего меню */
const NavItem: React.FC<NavItemProps> = ({ children, to }) => {
  const [opened, setOpened] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === to) {
      setOpened(true);
    } else {
      setOpened(false);
    }
  }, [location.pathname, to]);
  return (
    <Link
      className={parseStyles`
        text-md font-bold px-[0.5rem]
        ${opened ? 'underline underline-offset-4' : 'no-underline'}
      `}
      onClick={() => {
        const targetBlock = document.getElementById(to.slice(1));
        targetBlock?.scrollIntoView({ behavior: 'smooth' });
      }}
      to={{ pathname: '' }}
    >
      {children}
    </Link>
  );
};

interface TopBarProps {
  /** Открытие бокового меню */
  onMenuClick: () => void;
}

/** Шапка с меню навигации */
const TopBar: React.FC<TopBarProps> = ({ onMenuClick }) => (
  <div className="w-full h-[4rem] flex flex-row items-center justify-center pt-[2rem] pb-[1rem] bg-white z-40">
    <div className={parseStyles`
        flex flex-row w-full  
        justify-between z-10
        ${paddings.mxMain}
      `}
    >
      <div className="flex flex-row">
        <Link
          className="flex items-center"
          onClick={() => {
            document.getElementById('Header')?.scrollIntoView({ behavior: 'smooth' });
          }}
          to={{ pathname: '' }}
        >
          <Logo
            className="h-[1rem]"
          />
        </Link>
        <nav className="flex-row ml-[1.5rem] hidden md:block">
          <NavItem to={links.hardSkills}>Hardskills</NavItem>
          <NavItem to={links.timeMaterial}>T&M</NavItem>
          <NavItem to={links.fixedPrice}>FixedPrice</NavItem>
          <NavItem to={links.outsource}>Аутсорс</NavItem>
        </nav>
      </div>
      <div>
        <nav className="hidden md:block">
          <NavItem to={links.contacts}>Контакты</NavItem>
        </nav>
      </div>
      <div
        className="flex flex-row gap-x-[0.5rem] ml-auto mr-[1.5rem] font-bold text-xs cursor-pointer items-center md:hidden"
        onClick={onMenuClick}
      >
        <FontAwesomeIcon icon={faBars} />
        Меню
      </div>
    </div>
  </div>
);

/** Внешний лайаут */
const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-1 flex-col max-w-[100vw] overflow-x-hidden">
    {children}
  </div>
);

/** Внутренний лайаут (под боковое меню и контент) */
const InnerLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-1">
    {children}
  </div>
);

/** Лайаут блока с контентом */
const ContentLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <main className="flex flex-col w-full">
    {children}
  </main>
);

/** Обертка футера */
const Footer: React.FC<PropsWithChildren> = ({ children }) => (
  <footer
    className="p-5 w-full bg-[#1c1c1f] flex"
    id="Contacts"
  >
    <div className="text-white text-left uppercase font-medium text-sm tracking-[1px] flex flex-col gap-2">
      {children}
    </div>
  </footer>
);

/** Основной лайаут */
const MainLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const sidebarItems: SidebarItemProps[] = [
    {
      to: links.hardSkills,
      label: 'Hardskills',
    },
    {
      to: links.timeMaterial,
      label: 'T&M',
    },
    {
      to: links.fixedPrice,
      label: 'FixedPrice',
    },
    {
      to: links.outsource,
      label: 'Аутсорс',
    },
    {
      to: links.contacts,
      label: 'Контакты',
    },
  ];

  return (
    <Layout>
      <TopBar onMenuClick={() => setIsSidebarOpen(true)} />
      <div
        className="max-h-[calc(100vh-4rem)] overflow-y-auto"
        id="content"
      >
        <InnerLayout>
          <Sidebar
            closeSidebar={() => setIsSidebarOpen(false)}
            isOpen={isSidebarOpen}
            items={sidebarItems}
          />
          <ContentLayout>
            <Outlet />
            {children}
          </ContentLayout>
        </InnerLayout>
        <Footer>
          <span className="font-semibold text-lg">Казанский офис</span>
          <a
            className="hover:text-[#60afe6]"
            href="https://goo.gl/maps/bNdMQU2ACWysA7zq6"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              className="mr-[1rem]"
              icon={faLocationDot}
            />
            <span>
              улица Тукая, д.23, 2 этаж,
              Казань, 420021
            </span>
          </a>
          <a href="tel:+79950958700">
            <FontAwesomeIcon
              className="mr-[1rem]"
              icon={faPhoneFlip}
            />
            <span>
              +7 (995) 095 87 00
            </span>
          </a>
          <a href="mailto:info@a2soft.ru">
            <FontAwesomeIcon
              className="mr-[1rem]"
              icon={faEnvelope}
            />
            <span>info@a2soft.ru</span>
          </a>
          <span>ИНН 1657249187</span>
          <span>№10846 в реестре аккредитованных ИТ-компаний</span>
        </Footer>
      </div>
    </Layout>
  );
};
export default MainLayout;
